@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

body {
  font-family: 'Varela Round', sans-serif;
  background-image: url('/src/images/background_payment.jpg'); /* Path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional: This makes the background fixed on scroll */
  margin: 0;
  padding: 0;
}

.pricing-table h3,
.pricing-card .pricing-header,
.pricing-card .pricing-price,
.pricing-card .pricing-subtitle,
.pricing-card .pricing-features p,
.subscribe-button,
.payment-methods-modal .payment-details .card-title,
.email-input-container input,
.button-color {
    font-family: 'Varela Round', sans-serif;
}

/* Pricing Table Container */
.pricing-table {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

  }
  
  /* Pricing Panel */
  .pricing-panel {
    flex: 1;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    margin: 0 10px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }

  
  .pricing-panel:hover {
    transform: translateY(-5px);
  }
  
  /* Pricing Panel Title */
  .pricing-panel h3 {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  
  /* Pricing Panel Description */
  .pricing-panel p {
    font-size: 14px;
    color: #666666;
    margin-bottom: 20px;
  }
  
  /* Pricing Panel Price */
  .pricing-panel h2 {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  
  /* Pricing Panel Price Unit */
  .pricing-panel h2 span {
    font-size: 18px;
    font-weight: normal;
  }
  
  /* Pricing Panel Features List */
  .pricing-panel ul {
    list-style: none;
    padding: 0;
  }
  
  .pricing-panel ul li {
    font-size: 16px;
    color: #666666;
    margin-bottom: 10px;
  }
  
  /* Pricing Panel Button */
  .pricing-panel .rs-button {
    margin-top: 20px;
    display: block;
    width: 100%;
    background-color: #0070f3;
    color: #ffffff;
    border: none;
  }
  
  /* Media Query for Responsive Design */
  @media (max-width: 768px) {
    .pricing-table {
      flex-direction: column;
      align-items: stretch;
    }
  
    .pricing-panel {
      margin: 0 0 20px 0;
    }
  }
  

  .sssss{
    display: flex;
  }

  
  .pricing-table {
    display: flex;
    justify-content: center;
    /* gap: 20px; */
    
    padding: 20px;
}

.pricing-card {
  margin-top: 10px;
}

.pricing-card:first-child {
  margin-left: 0; /* Remove left margin for the first card */
}

.pricing-card:last-child {
  margin-right: 0; /* Remove right margin for the last card */
}

.pricing-card {
    background-color: #ffffffd9;
    border-color: #e2e2e2;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(103, 103, 103, 0.1);
    padding: 20px;
    text-align: center;
    /* width: 250px; */
}

.pricing-card.special-offer {
    border: 2px solid #6A5ACD; /* Highlight border color */
}

.pricing-header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.pricing-price {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin-bottom: 1px;
}

.pricing-subtitle {
    font-size: 12px;
    color: #888;
    margin-bottom: 20px;
}

.span{
  color: #51862a;
}

.pricing-price {
  position: relative;
  display: inline-block;
}

.extra-days {
  position: absolute;
  top: -22px; /* Adjust as needed */
  right: 0; /* Align to the right of the parent element */
  background-color: red; /* Change to your desired background color */
  border-radius: 10px; /* Adjust the radius as needed */
  padding: 2px 8px; /* Adjust padding as needed */
  font-size: 14px;
  color: white; 
  font-weight: bold;
}
cing-features {
    font-size: 12px;
    color: #333;
    margin-bottom: 20px;
}

.subscribe-button {
    background-color: #7ABC43;
    margin-top: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.subscribe-button:hover {
    background-color: #51862a;
}

.pricing-card.selected {
  border-color: #7ABC43;
  border-width: 3px;
}

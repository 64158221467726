.privacy-policy {
    padding: 20px;
}



.pb-8 {
    padding-bottom: 8px;
}

h5 {
    font-size: 18px;
    font-weight: bold;
}

p {
    font-size: 14px;
    line-height: 1.6;
}
.App {
    text-align: center;
}

.container {
    padding: 20px;
}

.download-section {
    margin: 20px 0;
}



.download-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.mobile-section {
    background-image: linear-gradient(rgba(20, 23, 41, .5), #141729), url(https://cdn.prod.website-files.com/5c7036349b5477bf13f828cf/63d85b4c41c346e96b901318_waves.jpg);
    background-position: 0 0, 50%;
    background-size: auto, cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 80px 40px;
    display: block;
    height: 600px;
}

.mobile-links img, .app-links img {
    width: 100px;
    margin: 10px;
}

.download-links {
    display: flex;
    justify-content: space-around;

}

.download-option {
    text-align: center;
    background-color: #ffffffb0;
    padding: 50px;
    border-radius: 20px;
}

.download-option button {
    background-color: #555;
    color: white;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.container {
    padding: 20px;
}

.download-section {
    margin: 20px 0;
    text-align: center; /* Center text and other elements */
}

.download-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.image-container {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.centered-image {
    max-width: 70%;
    max-height: 70%;
    height: auto;
}

.mobile-section {
    background-color: #f8f9fa;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
}

.mobile-links img, .app-links img {
    width: 100px;
    margin: 10px;
}

.download-links {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.download-option {
    text-align: center;
}

.download-option button {
    background-color: #555;
    color: white;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
